@import 'src/styles/shared';

.wrapper {
  position: relative;

  @include media-breakpoint-down(sm) {
    + .wrapper {
      margin-top: 16px;
    }
  }
}

input {
  font-size: 16px;
  height: 48px;
  padding-left: 16px;
  border-radius: 4px;
  border: 1px solid $gray_light;
  color: $black;
  caret-color: $primary;
  transition: .3s ease;

  &:hover {
    border-color: $gray;
  }
  &:focus {
    outline: 0;
    border-color: $primary;
  }
  &::placeholder {
    color: $dark_light;
  }
  &:disabled {
    background-color: $gray_lightest;
  }
}

.large {
  font-size: 18px;
  height: 64px;
  line-height: normal;
}

.dimmed {
  .input {
    background: $gray_shy;
    border-color: $gray_shy;
    color: $dark_light;
    height: 36px;
    font-size: inherit;
  }

  .leftAddon {
    left: 12px;
  }
  .rightAddon {
    right: 20px;
  }
}

.withAddon {
  .input {
    padding: 0 36px;
    width: 100%;
  }
}

.leftAddon, .rightAddon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.leftAddon {
  left: 16px;
}
.rightAddon {
  right: 24px;
}
.block {
  width: 100%;
  display: block;
}
