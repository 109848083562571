@import 'shared/variables';

html {
  font-family: 'Roboto', sans-serif;
}

body {
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-color--primary {
  color: $primary;
}
.text-color--black {
  color: $black;
}
.text-color--white {
  color: $white;
}
.text-color--dark {
  color: $dark;
}
.text-color--dark-light {
  color: $dark_light;
}
.text-color--gray {
  color: $gray;
}
.text-color--gray-light {
  color: $gray_light;
}
.text-color--gray-shy {
  color: $gray_shy;
}
.text-color--gray-lightest {
  color: $gray_lightest;
}
.text-color--success {
  color: $success;
}
.text-color--error {
  color: $error;
}
.text-color--warning {
  color: $warning;
}

.text-weight--regular {
  font-weight: normal;
}
.text-weight--medium {
  font-weight: 500;
}
.text-weight--bold {
  font-weight: 700;
}
.text-weight--black {
  font-weight: 900;
}

.text-clip {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
}

.text {
  &--small {
    font-size: 13px;
    line-height: 21px;
  }

  &--normal {
    @extend .text-weight--regular;
    font-size: 14px;
  }
}

.subtitle {
  line-height: 28px;
  @extend .text-weight--regular;
  &.medium {
    @extend .text-weight--medium;
  }

  &--normal {
    font-size: 18px;
  }

  &--small {
    font-size: 16px;

    &.bold {
      @extend .text-weight--bold;
    }
  }

  &.no-margin {
    margin: 0;
  }

  &.modal-sub { padding: 0 16px; margin: 12px 0 8px; }
}

.caption {
  font-size: 12px;
  line-height: 21px;

  &.medium {
    @extend .text-weight--medium;
  }

  &.regular {
    @extend .text-weight--regular;
  }
}

.t-left { text-align: left; }
.t-right { text-align: right; }
.t-center { text-align: center; }

.strike-through {
  text-decoration: line-through;
}
