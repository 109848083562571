@import 'src/styles/shared';

.header {
	padding: 24px 16px;

  @include media-breakpoint-down(xs) {
    &, &.noauth__header {
      padding: 16px 4px;
    }
  }
}

.logo {
	cursor: pointer;
	position: relative;
	z-index: 2;
	svg {
		width: 133px;
		display: block;
	}
}

.menu {
	margin-left: 32px;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;

	button {
		font-weight: 400;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	[class*="Label"] {
		height: auto;
		padding: 6px 8px;
		line-height: 1;
		display: inline-block;
		margin-left: 8px; }

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.auth {
	margin-left: auto;
	position: relative;
	z-index: 2;
}

.more__menu {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	background: $white;
	transition: .2s;
	z-index: -1;
  overflow: scroll;

  :global .branch-banner-is-active :local & {
    top: 76px;
  }

	&.is__active {
		display: block;
		opacity: 1;
		z-index: 3;
	}

	@include media-breakpoint-up(lg) {
    &, &.is__active {
      display: none;
    }
	}
}

.moreMenu__header {
	display: flex;
	height: 72px;
	padding: 0 16px;
	background: $white;
	align-items: center;
}

.moreMenu__close {
	margin-left: auto;
}

.moreMenu__section {
	padding: 16px 24px 24px;
	border-top: 1px solid $gray_shy;
}

.moreMenu__heading {
	font-size: 16px;
	composes: mb-3 d-block from global;
}

.text__withIcon {
	display: inline-flex;
	align-items: center;

	+ .text__withIcon {
		margin-left: 40px;
	}

	> div:first-child {
		margin-right: 12px;
	}
}

@include media-breakpoint-down(xs) {
	.btn__signup {
		height: 40px;
		font-size: 14px;
		padding-left: 13px;
		padding-right: 13px;
	}
}


.noauth__header {
	position: sticky;
	top: 0;
	z-index: 20;
	transition: all .3s ease;

	[class*="Button_link"] {
		font-weight: 400;
	}
}
:global(.js-background--gray-lightest) {
	.noauth__header {
		background: $gray_lightest;
	}
}
.isSticky {
	background: $white;
	box-shadow: 0px 6px 24px 0px rgba(76, 96, 114, 0.12);
}

.productMenu {
	display: none;
	background: $white;
	border-radius: 0 0 8px 8px;
  box-shadow: 0 21px 57px 0 rgba(76, 96, 114, 0.12), 0 2px 5px 0 rgba(109, 133, 158, 0.08);
  position: absolute;
  top: 91px; // change later to 100%
  left: 0;
  right: 0;
  border-top: 1px solid $gray_shy;
  padding: 46px 0 68px;
  @include media-breakpoint-down(md) {
  	display: none !important;
  }
  &:before {
  	content: '';
  	position: absolute;
  	bottom: calc(100% + 1px);
  	left: 0;
  	right: 0;
  	background: $white;
  	height: 100px;
  	z-index: 1;
  }
	[class*="container"] { position: relative; }


}
.productMenuActive {
	display: block;
}

.productMenuImage {
	width: 303px;
	height: 258px;
	position: absolute;
	right: 8px;
	bottom: -68px;
	background: url($assets_dir + '/other/phone.png') no-repeat;
	background-size: 100% 100%;

	@include retina {
		background-image: url($assets_dir + '/other/phone@2x.png');
	}

	@media (-webkit-min-device-pixel-ratio: 3) {
		background-image: url($assets_dir + '/other/phone@3x.png');
	}

	@include media-breakpoint-only(lg) {
		width: 220px;
    height: 187px;
	}
}

.productMenu_product {
	display: flex;
	align-items: center;
	padding: 14px 0;
	[class*="subtitle--small"] {
		line-height: 20px;
	}
	[class*="Icon"] {
		min-width: 40px;
	}
}

.appstore { height: 40px; }
.gplay { height: 58px; }


.dropdown {
	position: absolute;
	z-index: 10;
	right: -20px;
	top: 52px;
	background: $white;
	border-radius: 8px;
	width: 176px;
	box-shadow: 0 24px 48px 0 rgba(71, 96, 116, 0.24), 0 2px 6px 0 rgba($dark_light, 0.08);
	padding: 16px 16px;
	composes: text--small from global;
	opacity: 0;
	transition: .2s;
	pointer-events: none;

	&.is__active {
		top: 56px;
		opacity: 1;
		pointer-events: initial;
	}

	&.user {
		width: 300px;

		&.is__active {
			top: 72px;
		}

		&:before {
			content: '';
			position: absolute;
			top: -10px;
			right: 20px;
			border-bottom: 10px solid $white;
			border-right: 10px solid transparent;
			border-left: 10px solid transparent;
		}

		@include media-breakpoint-down(xs) {
			position: fixed;
			right: 8px;
			left: 8px;
			width: auto;
			padding: 20px 16px 10px;

			a,
			.btn {
				padding: 15px 14px;
				margin-bottom: 6px;

				img,svg {
					margin-right: 4px;
					width: 24px;
					height: 24px;
				}
				span {
					font-size: 16px;
				}

				:global(.caption) {
					font-size: 12px;
					line-height: 16px;
					float: right;
				}
			}
		}
	}

	a {
		:global(.caption) {
			padding: 2px 8px;

			@include media-breakpoint-down(xs) {
				padding: 4px 8px;
			}
		}
	}
	a,
	.btn {
		display: flex;
		align-items: center;
		color: $black;
		transition: all .3s ease;
		border-radius: 4px;
		cursor: pointer;
		text-decoration: none;
		padding: 11px 12px;

		img, svg {
			margin-right: 0;
			position: relative;
			top: -2px;
			width: 20px;
			height: 20px;
		}

		span {
			margin-left: 8px;
		}

		&:hover {
			background: $gray_shy;
		}
	}
}
