@import 'src/styles/shared';

.element {
}

.footer {
	border-top: 1px solid #edf4f8;
	margin: 0 -24px -24px;
	padding: 24px 35px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	span {
		display: block;
	}

	@include media-breakpoint-down(xs) {
		span:first-child {
			font-size: 18px;
		}

		:global(.caption) {
			font-size: 14px;
		}
	}
}

.title {
	margin: 0;
	line-height: normal;
}

.inner {
	display: inline-flex;
	align-items: center;
	width: 100%;
}

.icon {
	width: 21px;
	margin-right: 8px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.chevron {
	margin-left: 4px;
	@include media-breakpoint-down(sm) {
		margin-left: auto;
	}
}

.scrollbar {
	:global(.custom-scrollbar) {
		height: 132px !important;

		@include media-breakpoint-down(xs) {
			height: 300px !important;
		}
	}
}
