@import 'src/styles/shared';

.wrapper {
	margin-left: auto;
	composes: subtitle--small from global;
	position: relative;

  @include media-breakpoint-down(xs) {
    z-index: 4;
  }
}
.inner {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	.icon {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			border-radius: 50%;
			box-shadow: 0 32px 32px 0 rgba(34, 39, 47, 0.04), 0 4px 20px 0 rgba(109, 133, 158, 0.1);
			transition: opacity 0.2s ease-in-out;
			z-index: -1;
		}
	}

	&:hover {
		.icon {
			&:after {
				opacity: 1;
			}
		}
	}
}

.icon {
	margin-right: 12px;
	border-radius: 50%;
	@include media-breakpoint-down(sm) {
		margin-right: 0;
	}
}
.user {
  min-height: 60px;
	@include media-breakpoint-down(sm) {
		display: none;
	}
}
.chevron {
	width: 18px;
	height: 18px;
	margin-left: 5px;
	margin-top: -5px;
	img { max-width: 100%; }

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.line {
	height: 1px;
	background: $gray_shy;
	border: 0;
	margin: 16px -16px;
}

@include media-breakpoint-down(xs) {
  .inviteLink__mob {
    display: flex;
    justify-content: space-between;
  }

  .line {
    margin: 13px -16px 14px -16px;
  }
}
