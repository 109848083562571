@import 'src/styles/shared';

.button {
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  color: $white;
  transition: .3s ease;
  padding-left: 20px;
  padding-right: 20px;

  &:focus {
    outline: 0;
  }
  &:disabled {
    color: $dark_light;
    border: 1px solid $gray_shy;
    box-shadow: 0 1px 1px 0 rgba(216, 224, 234, 0.5);
    background-color: $gray_shy;
    &:hover {
      background-color: $gray_lightest;
    }
  }
  svg, img {
    position: relative;
    top: -1px;
  }
  svg path {
    transition: fill .3s ease;
  }
}

.primary {
  @include setBgColorAndHoverLighten($primary)
}
.success {
  @include setBgColorAndHoverLighten($success)
}
.danger {
  @include setBgColorAndHoverLighten($error)
}
.ghost {
  background-color: $white;
  color: $black;
  box-shadow: 0 1px 1px 0 rgba(216, 224, 234, 0.5);
  border: 1px solid $gray_light;
  &:hover {
    background-color: $gray_lightest;
  }
}
.link {
  background: transparent;
  border: transparent;
  color: $black;
  &:hover {
    color: shade($primary, 10%);
    svg path { fill: shade($primary, 10%); }
  }
}
:global .is-link-active {
  :local .link {
    background: transparent;
    border: transparent;
    color: $primary;
  }
}
.link-subtle {
  color: $gray;
  background-color: transparent;
  &:hover {
    color: shade($gray, 10%);
  }
}
.subtle {
  color: $primary;
  background-color: #edfaff;

  &:hover {
    color: $dark_primary;
  }
}


.primary:active, .danger:active {
  box-shadow: inset 0 3px 12px 0 rgba(76, 96, 114, 0.25);
}
.ghost:active {
  box-shadow: 0 1px 1px 0 rgba(216, 224, 234, 0.5), inset 0 3px 12px 0 rgba(76, 96, 114, 0.25);
}


.tiny {
  composes: text-weight--medium from global;
  line-height: normal;
  padding: 8px 10px;
}
.small {
  height: 40px;
  composes: text-weight--medium from global;
  line-height: normal;
}
.medium {
  height: 48px;
  composes: subtitle--small from global;
  composes: text-weight--medium from global;
  line-height: normal;
}
.large {
  height: 64px;
  composes: subtitle--normal from global;
  composes: text-weight--medium from global;
  line-height: normal;
}

.isLoading {
  border-color: rgba($primary, .1);
  position: relative;
  color: $primary;

  &, &:hover {
    background-color: rgba($primary, .1);
  }

  .loader :global {
    animation: spin 1s linear infinite;
    border-radius: 50%;
    border: 2px solid $primary;
    border-top: 2px solid transparent;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-bottom: -2px;
  }

  .loader__text {
    margin-left: 10px;
  }
}

.bordered { background-color: transparent; }

.block {
  width: 100%;
  display: block;
}
