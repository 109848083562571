@import 'src/styles/shared';

.loader {
	width: 108px;
  height: 108px;
  box-shadow: 0 24px 48px 0 rgba($dark_light, 0.1), 0 6px 12px 0 rgba($dark_light, 0.08);
  background-color: $white;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon :global {
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
    vertical-align: top;
  }
}
