@import 'src/styles/shared';
@import './LocaleFlagCoordinates.scss';

:global .locale-flag {
  width: 21px;
  height: 15px;
  display: inline-block;
  border-radius: 2px;

  background-image: url($assets_dir + '/flags/locale_sprite.png');
  @include retina() {
    background-image: url($assets_dir + '/flags/locale_sprite@2x.png');
  }
}

:global(.js-webp .locale-flag) {
  background-image: url($assets_dir + '/flags/locale_sprite.webp');
  @include retina() {
    background-image: url($assets_dir + '/flags/locale_sprite@2x.webp');
  }
}
