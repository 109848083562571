@import 'variables';

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
  margin-right: auto;
  margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .small-gutters {
    margin-right: -4px;
    margin-left: -4px;

    > .col,
    > [class*="col-"] {
      padding-right: 4px;
      padding-left: 4px;
    }
  }
  .no-margin {
    margin-right: 0;
    margin-left: 0;
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}


// stylelint-disable declaration-no-important

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none         { display: none !important; }
    .d#{$infix}-inline       { display: inline !important; }
    .d#{$infix}-inline-block { display: inline-block !important; }
    .d#{$infix}-block        { display: block !important; }
    .d#{$infix}-table        { display: table !important; }
    .d#{$infix}-table-row    { display: table-row !important; }
    .d#{$infix}-table-cell   { display: table-cell !important; }
    .d#{$infix}-flex         { display: flex !important; }
    .d#{$infix}-inline-flex  { display: inline-flex !important; }
  }
}


//
// Utilities for toggling `display` in print
//

@media print {
  .d-print-none         { display: none !important; }
  .d-print-inline       { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block        { display: block !important; }
  .d-print-table        { display: table !important; }
  .d-print-table-row    { display: table-row !important; }
  .d-print-table-cell   { display: table-cell !important; }
  .d-print-flex         { display: flex !important; }
  .d-print-inline-flex  { display: inline-flex !important; }
}


// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row            { flex-direction: row !important; }
    .flex#{$infix}-column         { flex-direction: column !important; }
    .flex#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
    .flex#{$infix}-column-reverse { flex-direction: column-reverse !important; }

    .flex#{$infix}-wrap         { flex-wrap: wrap !important; }
    .flex#{$infix}-nowrap       { flex-wrap: nowrap !important; }
    .flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
    .flex#{$infix}-fill         { flex: 1 1 auto !important; }
    .flex#{$infix}-grow-0       { flex-grow: 0 !important; }
    .flex#{$infix}-grow-1       { flex-grow: 1 !important; }
    .flex#{$infix}-shrink-0     { flex-shrink: 0 !important; }
    .flex#{$infix}-shrink-1     { flex-shrink: 1 !important; }

    .justify-content#{$infix}-start   { justify-content: flex-start !important; }
    .justify-content#{$infix}-end     { justify-content: flex-end !important; }
    .justify-content#{$infix}-center  { justify-content: center !important; }
    .justify-content#{$infix}-between { justify-content: space-between !important; }
    .justify-content#{$infix}-around  { justify-content: space-around !important; }

    .align-items#{$infix}-start    { align-items: flex-start !important; }
    .align-items#{$infix}-end      { align-items: flex-end !important; }
    .align-items#{$infix}-center   { align-items: center !important; }
    .align-items#{$infix}-baseline { align-items: baseline !important; }
    .align-items#{$infix}-stretch  { align-items: stretch !important; }

    .align-content#{$infix}-start   { align-content: flex-start !important; }
    .align-content#{$infix}-end     { align-content: flex-end !important; }
    .align-content#{$infix}-center  { align-content: center !important; }
    .align-content#{$infix}-between { align-content: space-between !important; }
    .align-content#{$infix}-around  { align-content: space-around !important; }
    .align-content#{$infix}-stretch { align-content: stretch !important; }

    .align-self#{$infix}-auto     { align-self: auto !important; }
    .align-self#{$infix}-start    { align-self: flex-start !important; }
    .align-self#{$infix}-end      { align-self: flex-end !important; }
    .align-self#{$infix}-center   { align-self: center !important; }
    .align-self#{$infix}-baseline { align-self: baseline !important; }
    .align-self#{$infix}-stretch  { align-self: stretch !important; }
  }
}

// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        .m#{$infix}-n#{$size} { margin: -$length !important; }
        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        .mr#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }
        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        .ml#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto { margin: auto !important; }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

// Bootstrap extra column
$extra_columns: (
  '0-5': 4.166666667%,
  '0-75': 6.25%,
  '1-5': 12.5%,
  'sm-1-5': 12.5%,
  '2-5': 20.83333%,
  '4-5': 37.5%,
);

@each $extra_column, $column_width in $extra_columns {
  .col-#{$extra_column} {
    max-width: $column_width;
    flex: 0 0 $column_width;
    @extend %grid-column;
  }
}
