:global{
.flag{background-size: 343px 304px; background-position: -322px -85px}
.flag--ad{background-position: -23px -0px}
.flag--ae{background-position: -207px -204px}
.flag--af{background-position: -0px -17px}
.flag--ag{background-position: -23px -17px}
.flag--ai{background-position: -46px -0px}
.flag--al{background-position: -46px -17px}
.flag--am{background-position: -0px -34px}
.flag--ao{background-position: -23px -34px}
.flag--ar{background-position: -46px -34px}
.flag--as{background-position: -0px -51px}
.flag--at{background-position: -23px -51px}
.flag--au{background-position: -46px -51px}
.flag--aw{background-position: -69px -0px}
.flag--ax{background-position: -69px -17px}
.flag--az{background-position: -69px -34px}
.flag--ba{background-position: -69px -51px}
.flag--bb{background-position: -0px -68px}
.flag--bd{background-position: -23px -68px}
.flag--be{background-position: -46px -68px}
.flag--bf{background-position: -69px -68px}
.flag--bg{background-position: -92px -0px}
.flag--bh{background-position: -92px -17px}
.flag--bi{background-position: -92px -34px}
.flag--bj{background-position: -92px -51px}
.flag--bl{background-position: -92px -68px}
.flag--bm{background-position: -0px -85px}
.flag--bn{background-position: -23px -85px}
.flag--bo{background-position: -46px -85px}
.flag--bq{background-position: -69px -85px}
.flag--br{background-position: -92px -85px}
.flag--bs{background-position: -115px -0px}
.flag--bt{background-position: -115px -17px}
.flag--bv{background-position: -115px -34px}
.flag--bw{background-position: -115px -51px}
.flag--by{background-position: -115px -68px}
.flag--bz{background-position: -115px -85px}
.flag--ca{background-position: -0px -102px}
.flag--cc{background-position: -23px -102px}
.flag--cd{background-position: -46px -102px}
.flag--cf{background-position: -69px -102px}
.flag--cg{background-position: -92px -102px}
.flag--ch{background-position: -115px -102px}
.flag--ci{background-position: -0px -119px}
.flag--ck{background-position: -23px -119px}
.flag--cl{background-position: -46px -119px}
.flag--cm{background-position: -69px -119px}
.flag--cn{background-position: -92px -119px}
.flag--co{background-position: -115px -119px}
.flag--cr{background-position: -138px -0px}
.flag--cu{background-position: -138px -17px}
.flag--cv{background-position: -138px -34px}
.flag--cw{background-position: -138px -51px}
.flag--cx{background-position: -138px -68px}
.flag--cy{background-position: -138px -85px}
.flag--cz{background-position: -138px -102px}
.flag--de{background-position: -138px -119px}
.flag--dj{background-position: -0px -136px}
.flag--dk{background-position: -23px -136px}
.flag--dm{background-position: -46px -136px}
.flag--do{background-position: -69px -136px}
.flag--dz{background-position: -92px -136px}
.flag--ec{background-position: -115px -136px}
.flag--ee{background-position: -138px -136px}
.flag--eg{background-position: -161px -0px}
.flag--eh{background-position: -161px -17px}
.flag--er{background-position: -161px -34px}
.flag--es{background-position: -161px -51px}
.flag--et{background-position: -161px -68px}
.flag--eu{background-position: -161px -85px}
.flag--fi{background-position: -161px -102px}
.flag--fj{background-position: -161px -119px}
.flag--fk{background-position: -161px -136px}
.flag--fm{background-position: -0px -153px}
.flag--fo{background-position: -23px -153px}
.flag--fr{background-position: -46px -153px}
.flag--ga{background-position: -69px -153px}
.flag--gb-eng{background-position: -92px -153px}
.flag--gb-nir{background-position: -115px -153px}
.flag--gb-sct{background-position: -138px -153px}
.flag--gb-wls{background-position: -161px -153px}
.flag--gb-zet{background-position: -184px -0px}
.flag--gb{background-position: -184px -17px}
.flag--gd{background-position: -184px -34px}
.flag--ge{background-position: -184px -51px}
.flag--gf{background-position: -184px -68px}
.flag--gg{background-position: -184px -85px}
.flag--gh{background-position: -184px -102px}
.flag--gi{background-position: -184px -119px}
.flag--gl{background-position: -184px -136px}
.flag--gm{background-position: -184px -153px}
.flag--gn{background-position: -0px -170px}
.flag--gp{background-position: -23px -170px}
.flag--gq{background-position: -46px -170px}
.flag--gr{background-position: -69px -170px}
.flag--gs{background-position: -92px -170px}
.flag--gt{background-position: -115px -170px}
.flag--gu{background-position: -138px -170px}
.flag--gw{background-position: -161px -170px}
.flag--gy{background-position: -184px -170px}
.flag--hk{background-position: -0px -187px}
.flag--hm{background-position: -23px -187px}
.flag--hn{background-position: -46px -187px}
.flag--hr{background-position: -69px -187px}
.flag--ht{background-position: -92px -187px}
.flag--hu{background-position: -115px -187px}
.flag--id{background-position: -138px -187px}
.flag--ie{background-position: -161px -187px}
.flag--il{background-position: -184px -187px}
.flag--im{background-position: -207px -0px}
.flag--in{background-position: -207px -17px}
.flag--io{background-position: -207px -34px}
.flag--iq{background-position: -207px -51px}
.flag--ir{background-position: -207px -68px}
.flag--is{background-position: -207px -85px}
.flag--it{background-position: -207px -102px}
.flag--je{background-position: -207px -119px}
.flag--jm{background-position: -207px -136px}
.flag--jo{background-position: -207px -153px}
.flag--jp{background-position: -207px -170px}
.flag--ke{background-position: -207px -187px}
.flag--kg{background-position: -0px -204px}
.flag--kh{background-position: -23px -204px}
.flag--ki{background-position: -46px -204px}
.flag--km{background-position: -69px -204px}
.flag--kn{background-position: -92px -204px}
.flag--kp{background-position: -115px -204px}
.flag--kr{background-position: -138px -204px}
.flag--kw{background-position: -161px -204px}
.flag--ky{background-position: -184px -204px}
.flag--kz{background-position: -0px -0px}
.flag--la{background-position: -230px -0px}
.flag--lb{background-position: -230px -17px}
.flag--lc{background-position: -230px -34px}
.flag--lgbt{background-position: -230px -51px}
.flag--li{background-position: -230px -68px}
.flag--lk{background-position: -230px -85px}
.flag--lr{background-position: -230px -102px}
.flag--ls{background-position: -230px -119px}
.flag--lt{background-position: -230px -136px}
.flag--lu{background-position: -230px -153px}
.flag--lv{background-position: -230px -170px}
.flag--ly{background-position: -230px -187px}
.flag--ma{background-position: -230px -204px}
.flag--mc{background-position: -0px -221px}
.flag--md{background-position: -23px -221px}
.flag--me{background-position: -46px -221px}
.flag--mf{background-position: -69px -221px}
.flag--mg{background-position: -92px -221px}
.flag--mh{background-position: -115px -221px}
.flag--mk{background-position: -138px -221px}
.flag--ml{background-position: -161px -221px}
.flag--mm{background-position: -184px -221px}
.flag--mn{background-position: -207px -221px}
.flag--mo{background-position: -230px -221px}
.flag--mp{background-position: -253px -0px}
.flag--mq{background-position: -253px -17px}
.flag--mr{background-position: -253px -34px}
.flag--ms{background-position: -253px -51px}
.flag--mt{background-position: -253px -68px}
.flag--mu{background-position: -253px -85px}
.flag--mv{background-position: -253px -102px}
.flag--mw{background-position: -253px -119px}
.flag--mx{background-position: -253px -136px}
.flag--my{background-position: -253px -153px}
.flag--mz{background-position: -253px -170px}
.flag--na{background-position: -253px -187px}
.flag--nc{background-position: -253px -204px}
.flag--ne{background-position: -253px -221px}
.flag--nf{background-position: -0px -238px}
.flag--ng{background-position: -23px -238px}
.flag--ni{background-position: -46px -238px}
.flag--nl{background-position: -69px -238px}
.flag--no{background-position: -92px -238px}
.flag--np{background-position: -115px -238px}
.flag--nr{background-position: -138px -238px}
.flag--nu{background-position: -161px -238px}
.flag--nz{background-position: -184px -238px}
.flag--om{background-position: -207px -238px}
.flag--pa{background-position: -230px -238px}
.flag--pe{background-position: -253px -238px}
.flag--pf{background-position: -0px -255px}
.flag--pg{background-position: -23px -255px}
.flag--ph{background-position: -46px -255px}
.flag--pk{background-position: -69px -255px}
.flag--pl{background-position: -92px -255px}
.flag--pm{background-position: -115px -255px}
.flag--pn{background-position: -138px -255px}
.flag--pr{background-position: -161px -255px}
.flag--ps{background-position: -184px -255px}
.flag--pt{background-position: -207px -255px}
.flag--pw{background-position: -230px -255px}
.flag--py{background-position: -253px -255px}
.flag--qa{background-position: -276px -0px}
.flag--re{background-position: -276px -17px}
.flag--ro{background-position: -276px -34px}
.flag--rs{background-position: -276px -51px}
.flag--ru{background-position: -276px -68px}
.flag--rw{background-position: -276px -85px}
.flag--sa{background-position: -276px -102px}
.flag--sb{background-position: -276px -119px}
.flag--sc{background-position: -276px -136px}
.flag--sd{background-position: -276px -153px}
.flag--se{background-position: -276px -170px}
.flag--sg{background-position: -276px -187px}
.flag--sh{background-position: -276px -204px}
.flag--si{background-position: -276px -221px}
.flag--sj{background-position: -276px -238px}
.flag--sk{background-position: -276px -255px}
.flag--sl{background-position: -0px -272px}
.flag--sm{background-position: -23px -272px}
.flag--sn{background-position: -46px -272px}
.flag--so{background-position: -69px -272px}
.flag--sr{background-position: -92px -272px}
.flag--ss{background-position: -115px -272px}
.flag--st{background-position: -138px -272px}
.flag--sv{background-position: -161px -272px}
.flag--sx{background-position: -184px -272px}
.flag--sy{background-position: -207px -272px}
.flag--sz{background-position: -230px -272px}
.flag--tc{background-position: -253px -272px}
.flag--td{background-position: -276px -272px}
.flag--tf{background-position: -299px -0px}
.flag--tg{background-position: -299px -17px}
.flag--th{background-position: -299px -34px}
.flag--tj{background-position: -299px -51px}
.flag--tk{background-position: -299px -68px}
.flag--tl{background-position: -299px -85px}
.flag--tm{background-position: -299px -102px}
.flag--tn{background-position: -299px -119px}
.flag--to{background-position: -299px -136px}
.flag--tr{background-position: -299px -153px}
.flag--tt{background-position: -299px -170px}
.flag--tv{background-position: -299px -187px}
.flag--tw{background-position: -299px -204px}
.flag--tz{background-position: -299px -221px}
.flag--ua{background-position: -299px -238px}
.flag--ug{background-position: -299px -255px}
.flag--um{background-position: -299px -272px}
.flag--us-ca{background-position: -0px -289px}
.flag--us{background-position: -23px -289px}
.flag--uy{background-position: -46px -289px}
.flag--uz{background-position: -69px -289px}
.flag--va{background-position: -92px -289px}
.flag--vc{background-position: -115px -289px}
.flag--ve{background-position: -138px -289px}
.flag--vg{background-position: -161px -289px}
.flag--vi{background-position: -184px -289px}
.flag--vn{background-position: -207px -289px}
.flag--vu{background-position: -230px -289px}
.flag--wf{background-position: -253px -289px}
.flag--ws{background-position: -276px -289px}
.flag--xk{background-position: -299px -289px}
.flag--ye{background-position: -322px -0px}
.flag--yt{background-position: -322px -17px}
.flag--za{background-position: -322px -34px}
.flag--zm{background-position: -322px -51px}
.flag--zw{background-position: -322px -68px}
}