@import 'src/styles/shared';
@import './FiatFlagCoordinates.scss';

:global .fiat-flag {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  background-image: url($assets_dir + '/flags/fiat_sprite.png');
}

:global(.js-webp .fiat-flag) {
  background-image: url($assets_dir + '/flags/fiat_sprite.webp');
}
