@import 'src/styles/shared';
@import './FlagCoordinates.scss';

:global .flag {
  width: 21px;
  height: 15px;
  display: inline-block;
  border-radius: 2px;

  background-image: url($assets_dir + '/flags/sprite_4x3.png');
  @include retina() {
    background-image: url($assets_dir + '/flags/sprite_4x3@2x.png');
  }
}

:global(.js-webp .flag) {
  background-image: url($assets_dir + '/flags/sprite_4x3.webp');
  @include retina() {
    background-image: url($assets_dir + '/flags/sprite_4x3@2x.webp');
  }
}
