@import 'src/styles/shared';

.select {
	position: relative;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	padding: 0;
	composes: text-weight--medium from global;
  z-index: 1;
}

.selector {
	display: flex;
	width: 100%;
	min-height: 40px;
	border: 1px solid $gray_light;
	border-radius: 4px;
	padding: 7px 7px 7px 12px;
}

.mobile {
	@include media-breakpoint-down(xs) {
		.inner {
			position: fixed;
			min-height: 400px;
			left: 8px;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 3;

			&:after {
				content: none;
			}

			> span {
				display: inline-block;
				margin-bottom: 16px;
				font-size: 24px;
				font-weight: 900;
			}

			.list {
				li {
					padding-top: 14px;
					padding-bottom: 14px;

					span {
						font-size: 16px;
					}
				}
			}
		}

		&:global(.fiat__picker) {
			.inner {
				margin-top: 35px;
        min-height: calc(100vh - 100px);
			}
		}
	}
}

:global(.btn__shadow) {
	box-shadow: 0 1px 1px 0 rgba(216, 224, 234, 0.5);
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	margin-left: 0 !important;
	background-color: rgba(176, 193, 211, 0.4);
	z-index: 2;
}

.inner {
	padding: 24px;
	background: $white;
	box-shadow: 0 24px 48px 0 rgba(71, 96, 116, 0.24), 0 2px 6px 0 rgba($dark_light, 0.08);
	border-radius: 8px;
	min-width: 253px;
	position: absolute;
	bottom: 25px;
	right: -11px;
	opacity: 0;
	transition: .2s;
	pointer-events: none;

	&.is__active {
		opacity: 1;
		bottom: 49px;
		pointer-events: initial;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		right: 17px;
		border-top: 10px solid $white;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		z-index: 2;
	}

	> span:first-child {
		display: inline-block;
		margin-bottom: 8px !important;

		@include media-breakpoint-down(xs) {
			margin-bottom: 20px !important;
		}
	}
    
  &.bottom {
    bottom: auto;
    top: 24px;
    left: 0;

    &.is__active {
      top: 44px;
    }

    &:after {
      bottom: auto;
      top: -20px;
      left: 7px;
      right: auto;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #FFFFFF;
    }
  }

	@include media-breakpoint-down(xs) {
		position: fixed;
		left: 8px;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
	}
}


.list {
	margin: 0;
	padding: 0;
	list-style: none;

	img, :global(.flag), :global(.fiat-flag), :global(.locale-flag) {
		display: inline-block;
		height: 15px;
		vertical-align: middle;
		position: relative;
		top: -1px;
		border-radius: 2px;
		margin-right: 8px;
	}

  img, :global(.fiat-flag) {
    height: 18px;
    border-radius: 50%;
  }

	li {
		padding: 10px 12px;
		border-radius: 3px;
		line-height: 24px;
		transition: .3s ease;
		cursor: pointer;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: $dark_light;

		&:hover {
			background: $gray_lightest;
		}
	}
}

.search {
	position: relative;
	min-width: 217px;
	margin-bottom: 12px;

	input {
		width: 100%;
		height: 36px;
		border: 0;
		border-radius: 3px;
		background: $gray_shy;
		font-size: inherit;
		font-weight: initial;
		padding: 0 35px;
	}
}

.close {
	position: absolute;
	top: 18px;
	right: 24px;
}
