@import 'src/styles/shared';

.label {
	padding: 5px 8px;
	color: $white;
	border-radius: 4px;
  font-size: 13px;
}

.primary {
	background-color: $primary;
}
.success {
	background-color: $success;
}
.error {
	background-color: $error;
}
.warning {
	background-color: rgba($warning, 0.1);
  color: $warning;
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 12px;

  :global(.progress-pie) {
    margin-left: -3px;
    background-color: mix($warning, $white, 20%);

    &:after {
      background-color: mix($warning, $white, 10%);
    }
  }
}
.ghost {
	background-color: $gray_shy;
	color: $dark_light;
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 12px;
}
