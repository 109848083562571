@import 'src/styles/shared';

.container {
  position: fixed;
  right: 32px;
  top: 32px;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    left: 10px;
    right: 10px;
    top: 15px;
    z-index: 5;

    > * {
      max-width: 100%;
      width: 404px;
      min-width: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
