@import 'src/styles/shared';

.notification {
  position: relative;
  min-width: 404px;
  composes: mb-3 from global;
  background: $white;
  border-radius: 8px;
  min-height: 80px;
  box-shadow: 0 24px 32px 0 rgba(76, 96, 114, 0.1), 0 2px 6px 0 rgba(109, 133, 158, 0.08);
  transition: all 0.6s ease;
  transform: translateY(-15px);
  opacity: 0;
  &.isVisible {
    opacity: 1;
    transform: translateY(0);
  }
}

.content {
  composes: d-flex from global;
  padding: 28px 24px;

  p {
    margin: 0;
  }
}

.icon {
  position: relative;
  align-items: center;
  composes: mr-3 d-flex from global;

  img {
    position: absolute;
    right: 2px;
    bottom: 2px;
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.progress {
  height: 5px;
  background-color: $primary;
  animation-name: fill;
  animation-timing-function: linear;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@keyframes fill {
  0% { width: 0; }
  100% { width: 100%; }
}

.hidden {
  visibility: hidden;
}
