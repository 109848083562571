@import 'src/styles/shared';

.overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	overflow: hidden;
	overflow-y: hidden;
	height: 0;
	opacity: 0;
	transition-property: height, opacity;
	transition-duration: 0ms, 200ms;
	transition-delay: 200ms, 0ms;
	background-color: rgba(176, 193, 211, 0.65);
}

:global(.js-overflow-hidden) .overlay {
  overflow-y: scroll;
}

.active {
	transition-delay: 0ms, 0ms;
	height: 100%;
	opacity: 1;
}
