@import 'src/styles/shared';

.wrap {
  padding-bottom: 40px;
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 12px;
  }
}

.wrapNotAuth {
  padding-bottom: 0;
	background-color: #fff;

  @include media-breakpoint-down(xs) {
    padding-top: 0;
  }
}

.footer {
	padding: 29px 12px;
	composes: container from global;

	@include media-breakpoint-down(xs) {
		padding: 20px 25px;
		flex-direction: column;
		padding-top: 0;
	}
}

.selects {
	margin-left: auto;
	display: flex;
	max-height: 45px;
  width: 100%;
  justify-content: flex-end;

  > div {
    margin-right: 8px;
    max-width: calc(50% - 4px);

    &:last-child {
      margin-right: 0;
    }
  }

	@include media-breakpoint-down(sm) {
		margin-left: initial;
		composes: mt-3 from global;
    justify-content: center;
		> div {
			min-width: 140px;
		}
	}
}
.footerLogo {
	width: 133px;
	margin-top: 7px;
	svg { width: 133px; }

	@include media-breakpoint-down(xs) {
		margin-top: 24px;
	}
}
.footerTitle {
	margin-bottom: 24px;
}
.footerLink {
	font-size: 16px;
	color: $dark_light;
	line-height: 26px;
	margin-bottom: 12px;
}

.footerCopy {
	align-items: center;
	justify-content: space-between;
	display: flex;

	&.anonymous {
		margin-top: 26px;
		padding: 24px 0;
		border-top: 1px solid $gray_shy;

		@include media-breakpoint-down(xs) {
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}
	}

	[class*="Icon"] {
		width: 36px;
		height: 36px;
		margin-left: 16px;
	}

	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
		justify-content: center;
		padding: 15px 0;
		text-align: center;
		[class*="Icon"] {
			margin: 0 8px;
		}
	}
}

.apps {
	display: flex;
	align-items: center;

	@include media-breakpoint-down(xs) {
		justify-content: center;
		margin-left: 10px;
		order: 1;
	}
}

.appstore {
	height: 38px;
}

.gplay {
	height: 56px;
	display: inline-block;
	margin-right: -9px;
}

.copyText {
	@include media-breakpoint-down(sm) {
		order: 4;
    width: 100%;
	}
}

.cards {
	flex: 0 0 100%;
	text-align: center;
	margin-right: auto;

	@include media-breakpoint-up(sm) {
		flex: 0 0 auto;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		margin-left: 0;
		order: 3;
		margin: 0 0 5px;
	}
}

.authPickersCol {
	@include media-breakpoint-down(md) {
		order: 4;
	}
}

.authCopyMobileCol {
	order: 5;
}
