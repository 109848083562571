h1 {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.3px;
}
h2 {
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.3px;
}
h3 {
  font-weight: 900;
  font-size: 18px;
  line-height: 26px;
}
