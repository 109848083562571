// *** COLORS *** //
// Main colors
$primary: #1AB6FF;
$black: #000000;
$white: #FFFFFF;
$dark_primary: #10a8f0;
$light_primary: #edf4f8;

// Gray colors
$dark: #4C6072;
$dark_light: #6D859E;
$gray: #B0C1D3;
$gray_light: #D3DDE8;
$gray_shy: #F5F9FB;
$gray_shy_dark: #EAF3F8;
$gray_lightest: #F8FBFD;
$gray_light_dark: #F4F9FB;

// State colors
$success: #2FC569;
$error: #E53636;
$warning: #F9A83E;

$red: #f91229;

// *** FONTS *** //

$font: 'Roboto', sans-serif;
$assets_dir: "/assets";
