@import 'src/styles/shared';

/**
 * Common
 */
.container {
	@include media-breakpoint-down(xs) {
		padding-left: 24px;
		padding-right: 24px;
	}
}

.app {
  width: 100%;
}

.title {
	line-height: 1.187;
	letter-spacing: -0.3px;
	margin: 16px 0 6px;
	font-size: 2rem;

	@include media-breakpoint-up(md) {
		font-size: 4rem;
		line-height: 1.125;
		font-weight: 900;
		letter-spacing: -0.56px;
		margin: 0.9375em 0 0.25em;
	}
}

.leadHeading {
	font-size: 1.9rem;

	@include media-breakpoint-up(md) {
		font-size: 2.875rem;
	}
}

.contentTitle {
  margin: 52px 0 16px;
}

.desc {
	line-height: 32px;
}

/**
 * Tables
 */
.table {
  background: $white;
  border: 1px solid $gray_light;
	color: $dark;
	margin: 0 -24px;
	border-left: 0;
	border-right: 0;

	@include media-breakpoint-up(sm) {
		margin: 0;
		border: 1px solid $gray_light;
		border-radius: 8px;
	}
}

.table__table {
  border: 0;
  width: 100%;

  a {
    line-height: 20px;
    color: $primary;

    &:active,
    &:focus {
      color: $primary;
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: lighten($primary, 10%);
    }
  }
  .grey {
    color: $gray;
  }
}

.table__head {
  text-align: center;
  font-size: 13px;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: $black;
  font-weight: 500;
  text-align: left;

	@include media-breakpoint-up(md) {
		font-size: 16px;
		line-height: 24px;
	}

  td {
		width: 33.33333%;
    padding: 18px 24px;

  }

  td + td {
    border-left: 1px solid $gray_light;
  }
}

.table__tableRow {
  text-align: left;
  font-size: 13px;
  line-height: 1.6;
  font-weight: 400;
	color: $black;

	@include media-breakpoint-up(md) {
		font-size: 16px;
		line-height: 28px;
	}

  &.normal {
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }

  td {
    padding: 16px 24px;
    border-top: 1px solid $gray_light;

		@include media-breakpoint-up(md) {
			white-space: normal;
		}
  }

  > * + * {
    border-left: 1px solid $gray_light;
  }

  [class*="Icon"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: -2px;
		font-size: 10px;
		color: $gray;
		margin-right: 3px;
		margin-left: -8px;

		@include media-breakpoint-up(md) {
			width: 24px;
			height: 24px;
			margin-right: 16px;
			margin-left: 0;
		}
	}
}

.table__cellHighlight {
	background: $gray_lightest;
}


/**
 * Contact
 */
.contact {
  &Wrapper {
    max-width: 486px;
    margin: 0 auto;

		input, textarea {
			box-shadow: 0 1px 1px rgba($gray_light, 0.4);
		}
  }

  &Label {
    margin-top: 16px;
    margin-bottom: 6px;

    &:first-of-type {
      margin-top: 42px;
    }
  }

  &Submit {
    margin-top: 32px;

		@include media-breakpoint-up(md) {
			margin-top: 48px;
		}
  }
}

/**
 * Centered header
 */
.centerHeader {
	@include media-breakpoint-up(md) {
		text-align: center;
	}

	&Title {
		@include media-breakpoint-up(md) {
			font-size: 2.875rem;
		}
	}
}

/**
 * Illustration page title
 */
.illuTitle {
	max-width: 530px;
	margin: 0 auto;
}

/**
 * Flex table cols
 */
.flexTableCols {
	border-top: solid 1px $gray_light;
	position: relative;
	margin: 0 -24px;

	@include media-breakpoint-up(sm) {
		margin: 0;
		border: solid 1px $gray_light;
		border-radius: 8px;
	}

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
	}

	&__Col {
		border-bottom: solid 1px $gray_light;
		padding: 21px 24px;

		p {
			margin: 0.1em 0;
		}

		@include media-breakpoint-up(sm) {
			padding: 24px;

			&:last-child {
				border-bottom: 0;
			}
		}

		@include media-breakpoint-up(md) {
			border: 0;
			width: 100%;

			&:before {
				content: '';
				width: 1px;
				height: 100%;
				position: absolute;
				background: $gray_light;
				top: 0;
				margin-left: -24px;
			}

			&:first-child:before {
				display: none;
			}
		}
	}

	&__isClear {
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
	}

	&__isClear & {
		&__Col {
			border: 0;

			padding: 24px 24px 0;

			&:last-child {
				padding-bottom: 24px;
				border-bottom: solid 1px $gray_light;
			}

			@include media-breakpoint-up(sm) {
				border: 0;

				&:last-child {
					border: 0;
				}
			}

			@include media-breakpoint-up(md) {
				width: auto;
				padding: 24px;
			}

			&:before {
				display: none;
			}
		}
	}
}

/**
 * About us
 */
.aboutUs {
	&Subtitle {
		font-size: 1.484rem;
		margin: 0.2em 0 1.2em;
		line-height: 1.67;

		@include media-breakpoint-up(md) {
			margin: 1em 0 1.6em;
			line-height: 1.75rem;
		}
	}

	&Heading {
		font-size: 2rem;
		margin: 33px 0 46px;

		@include media-breakpoint-up(md) {
			font-size: 2.9rem;
			margin: 61px 0 54px;
		}
	}

	&HeadingBreak {
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	&Content {
		max-width: 1090px;
		margin: 1.6em 0;

		@include media-breakpoint-up(md) {
			font-size: 1.125rem;
		}
	}
}

/**
 * Fit images
 */
.fitImages {
	:global .col-12 {
		@include media-breakpoint-down(sm) {
			margin-bottom: 24px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&Image {
		overflow: hidden;
		border-radius: 12px;
		width: 100%;
		height: 328px;
		padding-bottom: 100%;
		position: relative;

		@include media-breakpoint-up(md) {
			width: 100%;
			height: 328px;
			padding-bottom: 0;
		}

		img {
			width: auto;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 0;
			object-fit: cover;
			transform: translateX(-50%);

			@include media-breakpoint-up(md) {
				width: auto;
				height: 100%;
				position: relative;
			}

			@include media-breakpoint-up(lg) {
				width: auto;
				height: 100%;
				transform: translateX(0);
				position: static;
			}
		}
	}
}

/**
 * Legal menu
 */
.legalMenu {
  background-color: $gray_lightest;
  padding: 18px 0;
  width: 100%;

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 39px;
  }

  > :global(.container) {
    @include media-breakpoint-down(sm) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__link {
    font-size: 1rem;
    color: $dark_light;
    margin: 8px 0;
    margin-right: 2rem;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      margin-right: 0.74rem;
      margin-left: 0.74rem;
    }

    &:hover {
      color: $primary;
    }

    &Active {
      color: $primary;
      font-weight: 500;
    }
  }
}
