@import 'src/styles/shared';

.notification {
	display: flex;
	padding: 10px 71px 10px 15px;
	border-radius: 4px;
	position: relative;
	line-height: 24px;
	margin: 15px 0;
	text-align: left;

	svg {
		cursor: pointer;
		position: absolute;
		right: 16px;
		top: 10px;
	}

	.alert__icon {
		position: relative;
		right: initial;
		top: initial;
		margin-right: 12px;
		vertical-align: text-top;
	}

	&.no__close {
		padding-right: 15px;
	}
}

.primary {
	color: $primary;
	background: rgba($primary, .05);
}
.success {
	color: $success;
	background-color: rgba($success, .05);
}
.error {
	color: $error;
	background: rgba($error, .05);

	a {
		color: $error;
	}
}
.warning {
	color: $warning;
	background: rgba($warning, .05);
}
