/**
 * Images
 */
.img {
	&--responsive {
		max-width: 100%;
		height: auto;
	}
}

/**
 * Narrow containers
 */
.narrow {
	&--medium {
		max-width: 530px;
		margin: 0 auto;
	}

  &--large {
    max-width: 970px;
    margin: 0 auto;
  }
}

/**
 *
 */
.v-align {
	&--middle {
		vertical-align: middle;
	}
}

/**
 * Max dimensions
 */
.max-width-100 {
  max-width: 100%;
}
