@mixin setBgColorAndHoverDarken($baseColor) {
    background-color: $baseColor;
    &:hover {
        background-color: darken($baseColor, 10%);
    }
}

@mixin setBgColorAndHoverLighten($baseColor) {
    background-color: $baseColor;
    &:hover {
        background-color: lighten($baseColor, 10%);
    }
}


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@function assets_url($content){
  @return url("assets/" + $content)
}

@function image_url($content){
  @return url("../../../public/assets" + $content)
}
@function icon_url($content){
  @return image_url("/icon/" + $content)
}

