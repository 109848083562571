@import 'src/styles/shared';

.icon {
  display: inline-block;
  vertical-align: middle;
}

.xx-small {
  width: 12px;
  height: 12px;

  svg, path {
    width: 12px;
    height: 12px;
  }
}
.x-small {
  width: 16px;
  height: 16px;

  svg, path {
    width: 16px;
    height: 16px;
  }
}
.small, .normal {
  width: 20px;
  height: 20px;

  svg, path {
    width: 20px;
    height: 20px;
  }
}
.medium {
  width: 24px;
  height: 24px;

  svg, path {
    width: 24px;
    height: 24px;
  }
}
.large {
  width: 32px;
  height: 32px;

  svg, path {
    width: 32px;
    height: 32px;
  }

  &.withShadow {
    width: 84px;
    height: 84px;
    padding: 26px;
  }
}
.x-large {
  width: 48px;
  height: 48px;

  svg, path {
    width: 48px;
    height: 48px;
  }
}
.xx-large {
  width: 64px;
  height: 64px;

  svg, path {
    width: 64px;
    height: 64px;
  }
}

.withShadow {
  background: $white;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 0 24px 32px 0 rgba($black, 0.03), 0 2px 20px 0 rgba($dark_light, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  svg {
    width: 24px;
    height: 24px;
    display: block;
  }

  &.x-large {
    width: 88px;
    height: 88px;
    padding: 24px;
  }
}

.withShadowInlined {
  padding: 0 !important;
}

.primary {
  g, path {
    fill: $primary;
  }
}
.success {
  g, path {
    fill: $success;
  }
}
.error {
  g, path {
    fill: $error;
  }
}
.warning {
  g, path {
    fill: $warning;
  }
}
.dark {
  g, path {
    fill: $dark;
  }
}
.black {
  g, path {
    fill: $black;
  }
}
.gray {
  g, path {
    fill: $gray;
  }
}
.gray-light {
  g, path {
    fill: $gray_light;
  }
}
.dark-light {
  g, path {
    fill: $dark_light;
  }
}
.white {
  g, path {
    fill: $white;
  }
}

.circle {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span { display: flex; }

  // Size
  &--x-large {
    width: 84px;
    height: 84px;

    svg, path {
      width: 38px;
      height: 38px;
    }
  }
  &--large {
    width: 64px;
    height: 64px;

    svg, path {
      width: 30px;
      height: 30px;
    }
  }
  &--medium {
    width: 40px;
    height: 40px;
  }
  &--normal {
    width: 32px;
    height: 32px;
  }
  &--small {
    width: 24px;
    height: 24px;
  }
  &--x-small {
    width: 16px;
    height: 16px;
  }


  // Colors
  &--primary {
    background: $primary;
  }
  &--success {
    background: $success;
  }
  &--gray {
    background: $gray;
  }
  &--gray-shy {
    background: $gray_shy;
  }
  &--error {
    background: $error;
  }
  &--warning {
    background: $warning;
  }
  &--black {
    background: $black;
  }
  &--red {
    background: $red;
  }
  &--dark-light {
    background: $dark_light;
  }
  &--white {
    background: $white;
  }
}

.circleMark {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  path, g {
    fill: $white;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &--success,
  &--add {
    background-color: $success;
  }

  &--add {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
