@import 'src/styles/shared';

.horizontal,
.vertical {
	background: $gray_lightest;
  border-radius: 4px;

  div {
    border-radius: 2px !important;
    background-color: $gray_light !important;
    margin: 0 auto;
  }
}
.horizontal {
	height: 10px;
}

.vertical {
  width: 10px !important;
  right: 0;
  top: 5px;
  bottom: 5px;
  padding: 3px 0;
  div {
  	width: 4px !important;  	
  }
}
