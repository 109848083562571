@import 'normalize';
@import 'animations';
@import 'typography';
@import 'grid/mixins';
@import 'grid/global';
@import 'heading';
@import 'common';

body, html {
	background: $white;
	-webkit-overflow-scrolling: touch;
}

.app, #root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
}
.app__content {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	min-height: calc(100vh - 234px);
}
.js-background--gray-lightest {
	background: $gray_lightest;
}

.table-row {
	display: flex;
	margin: 0;
	align-items: center;
	border-bottom: 1px solid $gray_shy;
	padding: 12px 0;
}

.js-blur {
	filter: blur(8px);
}

.js-modal-blur {
	filter: blur(5px);
	opacity: 0.1;
}

.js-overflow-hidden {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}

.js-menuopen-overflow {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;

  /* Mobile scrolling cutted off background fix */
  .app {
    position: relative;

    &:after {
  		content: '';
  		width: 100%;
  		height: 100%;
  		left: 0;
  		top: 0;
  		position: absolute;
  		background: #fff;
  		z-index: 15;
  	}
  }
}

.key-val {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 4px;

	.subtitle {
		margin: 0;
		display: inline-block;
		+ .subtitle { margin-left: 4px; }

		@include media-breakpoint-down(xs) {
			font-size: 16px;
		}
	}

	&.bordered {
		padding: 16px 4px;
		+ .key-val.bordered { border-top: 1px solid $gray_lightest; }
	}
}
.key-val-box {
  .details {
    margin: 0;
    text-align: left;
    padding-left: 4px;
    word-wrap: break-word;
    @extend .subtitle--small;
  }
  &.bordered {
    padding: 12px 0 16px 0;
    + .key-val-box.bordered { border-top: 1px solid $gray_shy; }
  }

  &.column-spaced-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.small {
    padding: 8px 0 12px 0;
  }
}
.key-val__right {
	display: flex;
	align-items: center;
	max-width: 65%;

	svg,img { cursor: pointer; }

	> * {
		margin-left: 12px;
		&:first-child { margin-left: 0; }
	}
	.subtitle {
		max-width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@include media-breakpoint-down(xs) {
		max-width: 80%;
	}
}

a {
	text-decoration: none;
	color: $primary;
}
.input-with-label {
	p { margin: 8px 0 6px 0; }
}
.body-info {
	margin: 27px 0;
}

.amount-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	&:before {
		content: '≈';
		position: absolute;
		left: 50%;
		top: 65px;
		margin-left: -5px;

		@extend .subtitle--small;
		@extend .text-color--dark-light;
	}
	> * {
		width: calc(50% - 25px);
	}
}

.faded__bottom {
	&:after {
		content: '';
		display: block;
		position: relative;
		bottom: 55px;
		height: 56px;
		background-image: linear-gradient(
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		pointer-events: none;
	}
}

.search__icon {
		display: inline-block;
		margin-bottom: 2px;
}

.circled__hover, .hovered__ripple {
	padding: 10px;
	border-radius: 50%;
	cursor: pointer;
	line-height: 0.5rem;
	display: inline-block;

	span {
		display: inline-block;
		vertical-align: top;
	}

	svg {
		vertical-align: top;
	}

	&:hover {
		background: $gray_shy;
		path {
			fill: $dark_light;
		}
	}
}

.ripple__effect, .hovered__ripple {
	background-position: center;
	transition: background 0.5s;

	&:hover {
		background: $gray_shy radial-gradient(circle, transparent 1%, $gray_shy 1%) center/15000%;
	}

	&:active {
		background-color: $gray_light;
		background-size: 100%;
		transition: background 0s;
	}
}

.cursor--pointer {
	cursor: pointer;
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.hover_with_icon {
	.icon__white {
			display: none;
		}

		&:hover {
			background: $gray_lightest;

			.icon__white {
				display: inline-block;
			}

			.hovered__icon {
				display: none;
			}
		}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	background-color: rgba(176, 193, 211, 0.4);
	z-index: 2;
}
.sticky-btn {
	@include media-breakpoint-down(sm) {
		position: sticky;
		bottom: 16px;
		width: calc(100% - 32px);
		left: 16px;
		z-index: 1;
		margin-bottom: 16px;
	}
  .flex-1 {
    flex: 1;
  }
}
.full-width {
  width: 100%;
}
// Crisp chat
.crisp-client {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 99999;
}
body .crisp-client [class*="crisp-"][data-full-view=true] [class*="crisp-"] a[class*="crisp-"] {
	@include media-breakpoint-down(sm) {
		bottom: 15px !important;
	}
}
