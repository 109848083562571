@import 'src/styles/shared';

@include media-breakpoint-down(sm) {
  .gplay {
    height: 66px;
  }
  .appstore {
    height: 45px;
  }
}
