@import 'src/styles/shared';

.inner {
	display: inline-flex;
	align-items: center;
	width: 100%;
  line-height: 1.1;
}
.icon {
	width: 24px;
	margin-right: 7px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.chevron {
	margin-left: 4px;
	@include media-breakpoint-down(sm) {
		margin-left: auto;
	}
}

.scrollbar {
	:global(.custom-scrollbar) {
		height: 250px !important;

		@include media-breakpoint-down(xs) {
			height: calc(100vh - 216px) !important;
		}
	}
}


.dropdownItem {
	display: flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	transition: .3s ease;

	p { margin: 0; }
	&:hover {
		background-color: $gray_lightest;
	}
}
