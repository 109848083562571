:global{
.fiat-flag{background-size: 258px 258px; background-position: -120px -100px}
.fiat-flag--aed{background-position: -20px -0px}
.fiat-flag--afn{background-position: -180px -40px}
.fiat-flag--all{background-position: -0px -20px}
.fiat-flag--amd{background-position: -20px -20px}
.fiat-flag--ang{background-position: -40px -0px}
.fiat-flag--aoa{background-position: -40px -20px}
.fiat-flag--ars{background-position: -0px -40px}
.fiat-flag--aud{background-position: -20px -40px}
.fiat-flag--awg{background-position: -40px -40px}
.fiat-flag--azn{background-position: -60px -0px}
.fiat-flag--bam{background-position: -60px -20px}
.fiat-flag--bbd{background-position: -60px -40px}
.fiat-flag--bdt{background-position: -0px -60px}
.fiat-flag--bgn{background-position: -20px -60px}
.fiat-flag--bhd{background-position: -40px -60px}
.fiat-flag--bif{background-position: -60px -60px}
.fiat-flag--bmd{background-position: -80px -0px}
.fiat-flag--bnd{background-position: -80px -20px}
.fiat-flag--bob{background-position: -80px -40px}
.fiat-flag--bov{background-position: -80px -60px}
.fiat-flag--brl{background-position: -0px -80px}
.fiat-flag--bsd{background-position: -20px -80px}
.fiat-flag--btn{background-position: -40px -80px}
.fiat-flag--bwp{background-position: -60px -80px}
.fiat-flag--byn{background-position: -80px -80px}
.fiat-flag--bzd{background-position: -100px -0px}
.fiat-flag--cad{background-position: -100px -20px}
.fiat-flag--cdf{background-position: -100px -40px}
.fiat-flag--che{background-position: -100px -60px}
.fiat-flag--chf{background-position: -100px -80px}
.fiat-flag--chw{background-position: -0px -100px}
.fiat-flag--clf{background-position: -20px -100px}
.fiat-flag--clp{background-position: -40px -100px}
.fiat-flag--cny{background-position: -60px -100px}
.fiat-flag--cop{background-position: -80px -100px}
.fiat-flag--cou{background-position: -100px -100px}
.fiat-flag--crc{background-position: -120px -0px}
.fiat-flag--cuc{background-position: -120px -20px}
.fiat-flag--cup{background-position: -120px -40px}
.fiat-flag--cve{background-position: -120px -60px}
.fiat-flag--czk{background-position: -120px -80px}
.fiat-flag--djf{background-position: -0px -120px}
.fiat-flag--dkk{background-position: -20px -120px}
.fiat-flag--dop{background-position: -40px -120px}
.fiat-flag--dzd{background-position: -60px -120px}
.fiat-flag--egp{background-position: -80px -120px}
.fiat-flag--ern{background-position: -100px -120px}
.fiat-flag--etb{background-position: -120px -120px}
.fiat-flag--eur{background-position: -140px -0px}
.fiat-flag--fjd{background-position: -140px -20px}
.fiat-flag--fkp{background-position: -140px -40px}
.fiat-flag--gbp{background-position: -140px -60px}
.fiat-flag--gel{background-position: -140px -80px}
.fiat-flag--ghs{background-position: -140px -100px}
.fiat-flag--gip{background-position: -140px -120px}
.fiat-flag--gmd{background-position: -0px -140px}
.fiat-flag--gnf{background-position: -20px -140px}
.fiat-flag--gtq{background-position: -40px -140px}
.fiat-flag--gyd{background-position: -60px -140px}
.fiat-flag--hkd{background-position: -80px -140px}
.fiat-flag--hnl{background-position: -100px -140px}
.fiat-flag--hrk{background-position: -120px -140px}
.fiat-flag--htg{background-position: -140px -140px}
.fiat-flag--huf{background-position: -160px -0px}
.fiat-flag--idr{background-position: -160px -20px}
.fiat-flag--ils{background-position: -160px -40px}
.fiat-flag--inr{background-position: -160px -60px}
.fiat-flag--iqd{background-position: -160px -80px}
.fiat-flag--irr{background-position: -160px -100px}
.fiat-flag--isk{background-position: -160px -120px}
.fiat-flag--jmd{background-position: -160px -140px}
.fiat-flag--jod{background-position: -0px -160px}
.fiat-flag--jpy{background-position: -20px -160px}
.fiat-flag--kes{background-position: -40px -160px}
.fiat-flag--kgs{background-position: -60px -160px}
.fiat-flag--khr{background-position: -80px -160px}
.fiat-flag--kmf{background-position: -100px -160px}
.fiat-flag--kpw{background-position: -120px -160px}
.fiat-flag--krw{background-position: -140px -160px}
.fiat-flag--kwd{background-position: -160px -160px}
.fiat-flag--kyd{background-position: -180px -0px}
.fiat-flag--kzt{background-position: -180px -20px}
.fiat-flag--lak{background-position: -0px -0px}
.fiat-flag--lbp{background-position: -180px -60px}
.fiat-flag--lkr{background-position: -180px -80px}
.fiat-flag--lrd{background-position: -180px -100px}
.fiat-flag--ltl{background-position: -180px -120px}
.fiat-flag--lyd{background-position: -180px -140px}
.fiat-flag--mad{background-position: -180px -160px}
.fiat-flag--mdl{background-position: -0px -180px}
.fiat-flag--mga{background-position: -20px -180px}
.fiat-flag--mkd{background-position: -40px -180px}
.fiat-flag--mmk{background-position: -60px -180px}
.fiat-flag--mnt{background-position: -80px -180px}
.fiat-flag--mop{background-position: -100px -180px}
.fiat-flag--mro{background-position: -120px -180px}
.fiat-flag--mur{background-position: -140px -180px}
.fiat-flag--mvr{background-position: -160px -180px}
.fiat-flag--mwk{background-position: -180px -180px}
.fiat-flag--mxn{background-position: -200px -0px}
.fiat-flag--mxv{background-position: -200px -20px}
.fiat-flag--myr{background-position: -200px -40px}
.fiat-flag--mzn{background-position: -200px -60px}
.fiat-flag--nad{background-position: -200px -80px}
.fiat-flag--ngn{background-position: -200px -100px}
.fiat-flag--nio{background-position: -200px -120px}
.fiat-flag--nok{background-position: -200px -140px}
.fiat-flag--npr{background-position: -200px -160px}
.fiat-flag--nzd{background-position: -200px -180px}
.fiat-flag--omr{background-position: -0px -200px}
.fiat-flag--pab{background-position: -20px -200px}
.fiat-flag--pen{background-position: -40px -200px}
.fiat-flag--pgk{background-position: -60px -200px}
.fiat-flag--php{background-position: -80px -200px}
.fiat-flag--pkr{background-position: -100px -200px}
.fiat-flag--pln{background-position: -120px -200px}
.fiat-flag--pyg{background-position: -140px -200px}
.fiat-flag--qar{background-position: -160px -200px}
.fiat-flag--ron{background-position: -180px -200px}
.fiat-flag--rsd{background-position: -200px -200px}
.fiat-flag--rub{background-position: -220px -0px}
.fiat-flag--rwf{background-position: -220px -20px}
.fiat-flag--sar{background-position: -220px -40px}
.fiat-flag--sbd{background-position: -220px -60px}
.fiat-flag--scr{background-position: -220px -80px}
.fiat-flag--sdg{background-position: -220px -100px}
.fiat-flag--sek{background-position: -220px -120px}
.fiat-flag--sgd{background-position: -220px -140px}
.fiat-flag--shp{background-position: -220px -160px}
.fiat-flag--sll{background-position: -220px -180px}
.fiat-flag--sos{background-position: -220px -200px}
.fiat-flag--srd{background-position: -0px -220px}
.fiat-flag--ssp{background-position: -20px -220px}
.fiat-flag--std{background-position: -40px -220px}
.fiat-flag--svc{background-position: -60px -220px}
.fiat-flag--syp{background-position: -80px -220px}
.fiat-flag--szl{background-position: -100px -220px}
.fiat-flag--thb{background-position: -120px -220px}
.fiat-flag--tjs{background-position: -140px -220px}
.fiat-flag--tmt{background-position: -160px -220px}
.fiat-flag--tnd{background-position: -180px -220px}
.fiat-flag--top{background-position: -200px -220px}
.fiat-flag--try{background-position: -220px -220px}
.fiat-flag--ttd{background-position: -240px -0px}
.fiat-flag--twd{background-position: -240px -20px}
.fiat-flag--tzs{background-position: -240px -40px}
.fiat-flag--uah{background-position: -240px -60px}
.fiat-flag--ugx{background-position: -240px -80px}
.fiat-flag--usd{background-position: -240px -100px}
.fiat-flag--usn{background-position: -240px -120px}
.fiat-flag--uyi{background-position: -240px -140px}
.fiat-flag--uyu{background-position: -240px -160px}
.fiat-flag--uyw{background-position: -240px -180px}
.fiat-flag--uzs{background-position: -240px -200px}
.fiat-flag--vef{background-position: -240px -220px}
.fiat-flag--vnd{background-position: -0px -240px}
.fiat-flag--vuv{background-position: -20px -240px}
.fiat-flag--wst{background-position: -40px -240px}
.fiat-flag--xaf{background-position: -60px -240px}
.fiat-flag--xcd{background-position: -80px -240px}
.fiat-flag--xpf{background-position: -100px -240px}
.fiat-flag--yer{background-position: -120px -240px}
.fiat-flag--zar{background-position: -140px -240px}
.fiat-flag--zmw{background-position: -160px -240px}
.fiat-flag--zwl{background-position: -180px -240px}
}